<template>
    <div class="billingRecord-list">
        <app-list :opt="opt" @get="onGet" ref="myList"></app-list>
        <el-dialog title="查看详情" width="500px" height="585px" :visible.sync="invoiceDialogVisible"
            :close-on-click-modal="false">
            <div class="billingRecord-detail">
                <div class="billingRecord-detail-row">
                    <span class="billingRecord-detail-title">发票类型</span>
                    <span>{{form.fpTypeStr}}</span>
                </div>
                <div class="billingRecord-detail-row">
                    <span class="billingRecord-detail-title">发票内容</span>
                    <span>商品明细，订单号：{{form.orderId}}</span>
                </div>
                <div class="billingRecord-detail-row">
                    <span class="billingRecord-detail-title">抬头类型</span>
                    <span>{{form.fpTitleStr}}</span>
                </div>
                <div class="billingRecord-detail-row">
                    <span class="billingRecord-detail-title">抬头名称</span>
                    <span>{{form.fpTitleName}}</span>
                </div>
                <div v-if="form.fpTitle == 2">
                    <div class="billingRecord-detail-row">
                        <span class="billingRecord-detail-title">单位税号</span>
                        <span>{{form.coTaxNumber}}</span>
                    </div>
                    <div class="billingRecord-detail-row">
                        <span class="billingRecord-detail-title">注册地址</span>
                        <span>{{form.coAddress}}</span>
                    </div>
                    <div class="billingRecord-detail-row">
                        <span class="billingRecord-detail-title">注册电话</span>
                        <span>{{form.coPhone}}</span>
                    </div>
                    <div class="billingRecord-detail-row">
                        <span class="billingRecord-detail-title">开户银行</span>
                        <span>{{form.coBank}}</span>
                    </div>
                    <div class="billingRecord-detail-row">
                        <span class="billingRecord-detail-title">银行账号</span>
                        <span>{{form.coAccountBank}}</span>
                    </div>
                </div>

                <div class="billingRecord-detail-row">
                    <span class="billingRecord-detail-title">邮箱地址</span>
                    <span>{{form.sendEmail}}</span>
                    <span class="clickable sendEmailStyle" @click="clickSendEmail">发送到邮箱</span>
                </div>
                <div class="billingRecord-detail-row">
                    <span class="billingRecord-detail-title">开票金额</span>
                    <span>{{form.invoiceTotalPriceTax}} 元</span>
                </div>
                <div class="billingRecord-detail-row">
                    <span class="billingRecord-detail-title">开票时间</span>
                    <span>{{form.invoiceDate}}</span>
                </div>
                <div class="billingRecord-detail-row">
                    <span class="billingRecord-detail-title">申请时间</span>
                    <span>{{form.fpApplyDate}}</span>
                </div>
                <div class="billingRecord-detail-row">
                    <span class="billingRecord-detail-title">查看发票</span>
                    <span class="clickable" @click="checkInvoice">发票预览</span>
                </div>
                <div class="billingRecord-detail-row">
                    <span class="billingRecord-detail-title">开票状态</span>
                    <span>{{form.fpStateStr}}</span>
                </div>
                <div class="billingRecord-detail-row" v-if="form.fpState == 2">
                    <span class="billingRecord-detail-title">失败原因</span>
                    <span>{{form.fpError}}</span>
                </div>
                <div class="dialog-footer">
                    <el-button @click="invoiceDialogVisible = false">关闭</el-button>
                </div>
            </div>
        </el-dialog>
        <!-- 邮箱弹框 -->
        <el-dialog title="发送到邮箱" top="35vh" center width="320px" height="260px" :visible.sync="emailDialogVisible"
            :close-on-click-modal="false">
            <div style="padding-bottom:15px;">
                <el-input placeholder="请输入邮箱" v-model.trim="form.sendEmail"
                    @input="verifyMailboxFormat(form.sendEmail)">
                </el-input>
                <div class="errorTips" v-if="errorTips">邮箱格式不正确</div>
            </div>
            <div class="dialog-footer">
                <el-button @click="emailDialogVisible = false">取消</el-button>
                <el-button type="primary" @click="confirmSendEmail">发送</el-button>
            </div>
        </el-dialog>
        <!-- 发票预览 -->
        <el-dialog title="发票预览" top="10vh" :visible.sync="invoicePreview" :close-on-click-modal="false">
            <!-- <pdf :src='pSrc' :page="currentPage" @num-pages="pageCount=$event" @page-loaded="currentPage=$event"
                @loaded="loadPdfHandler"></pdf> -->
            <iframe :src="pSrc" height="480vh" width="100%" frameborder="0"></iframe>
            <div class="dialog-footer">
                <el-button type="primary" @click="download()">下载</el-button>
                <el-button @click="invoicePreview = false">关闭</el-button>
            </div>
        </el-dialog>

    </div>
</template>
<script>
    import cabinetUtil from "@utils/cabinetUtil.js"
    let validTitleName = (rule, value, callback) => {
        if (value == "" || value == undefined) {
            callback();
        } else {
            //仅支持输入汉字，大小写字母，限制字符长度1~64个字符
            let reg = /^[a-zA-Z\u4e00-\u9fa5]{1,64}$/;
            if (!reg.test(value)) {
                callback(new Error("抬头名称格式不对"));
            } else {
                callback();
            }
        }
    };
    import axios from "axios";
    import Vue from "vue";
    import pdf from 'vue-pdf';
    export default {
        components: {
            pdf
        },
        data() {
            let _this = this;
            return {
                errorTips: false,
                currentPage: 0,
                pageCount: 0,
                pSrc: '',
                invoicePreview: false,
                invoiceDialogVisible: false,
                emailDialogVisible: false,
                form: {
                    fpTypeStr: '',
                    orderId: '',
                    fpTitle: '',
                    fpTitlleName: '',
                    coAddress: '',
                    coPhone: '',
                    coBank: '',
                    coAccountBank: '',
                    sendEmail: '',
                    invoiceTotalPriceTax: '',
                    invoiceDate: '',
                    fpApplyDate: '',
                    fpStateStr: '',
                    fpError: '',
                    fpState: 0,
                    id: '',
                    coTaxNumber: '',
                },
                opt: {
                    title: "开票记录",
                    search: [{
                            key: "merchantId",
                            label: "商家名称",
                            type: 'remoteSearchById',
                            opt: {
                                list: []
                            }
                        }, {
                            key: "date",
                            label: "申请日期",
                            type: "data-range"
                        },
                        {
                            key: "titleName",
                            label: "抬头名称",
                            maxlength: 64,
                            rules: [{
                                validator: validTitleName,
                                trigger: ['blur']
                            }]
                        }
                    ],
                    columns: [{
                            label: "商家名称",
                            key: "merchantName"
                        }, {
                            label: "申请时间",
                            key: "fpApplyDate",
                        },
                        {
                            label: "抬头名称",
                            key: "fpTitleName"
                        },
                        {
                            label: "开票金额",
                            key: "invoiceTotalPriceTax"
                        },
                        {
                            label: "开票类型",
                            key: "fpTypeStr" //1：电子普通发票；2：增值税专用发
                        },
                        {
                            label: "售货订单号",
                            key: "orderId"
                        },
                        {
                            label: "商品类目名称",
                            key: "itemName"
                        },
                        {
                            label: "开票状态",
                            key: "fpStateStr" //0：开票中；1：开票成功；2：开票失败：3：已红冲
                        },
                        {
                            label: "操作",
                            key: "option",
                            type: "action-but",
                            opt: {
                                list: [{
                                    label: "查看",
                                    on(row) {
                                        _this.invoiceDialogVisible = true;
                                        _this.get('/mall-service/v1/order-fp/get-detail?id=' + row.id).then(
                                            res => {
                                                _this.form.fpTypeStr = ['电子普通发票', '增值税专用发票'][res
                                                    .fpType - 1
                                                ];
                                                _this.form.orderId = res.orderId;
                                                _this.form.fpTitleStr = ['个人', '单位'][res.fpTitle - 1];
                                                _this.form.fpTitleName = res.fpTitleName;
                                                _this.form.coAddress = res.coAddress ? res.coAddress :
                                                    '--';
                                                _this.form.coPhone = res.coPhone ? res.coPhone : '--';
                                                _this.form.coBank = res.coBank ? res.coBank : '--';
                                                _this.form.coAccountBank = res.coAccountBank ? res
                                                    .coAccountBank : '--';
                                                _this.form.sendEmail = res.sendEmail;
                                                _this.form.invoiceTotalPriceTax = res
                                                    .invoiceTotalPriceTax;
                                                _this.form.invoiceDate = res.invoiceDate;
                                                _this.form.fpApplyDate = res.fpApplyDate;
                                                _this.form.fpStateStr = ['开票中', '开票成功', '开票失败', '已红冲'][
                                                    res.fpState
                                                ];
                                                _this.form.fpError = res.fpState == 2 ? '' : res
                                                    .fpError;
                                                _this.form.fpState = res.fpState;
                                                _this.form.id = res.id;
                                                _this.form.fpTitle = res.fpTitle;
                                                // 预发布和生产 需要将pdf地址转成https协议 否则无法显示
                                                if (Vue.config.BASE_URL ==
                                                    'https://apartment-preapi.wfyundata.com' || Vue
                                                    .config.BASE_URL ==
                                                    'https://apartment-api.wavewisdom.com') {
                                                    _this.pSrc = res.fpPdfUrl.replace(/http/g, "https")
                                                } else {
                                                    _this.pSrc = res.fpPdfUrl
                                                }
                                                _this.form.coTaxNumber = res.coTaxNumber;
                                            })

                                    }
                                }]
                            }
                        },
                    ],
                },
                merchantFlag: false,
            };
        },
        created() {
            console.log("billingRecord-list created!!");
        },
        activated() {
            console.log("billingRecord-list activated!!");
            this.merchantFlag = this.getLoginType('loginType');
            if (this.merchantFlag) {
                this.opt.search.forEach((item, index) => {
                    if (item.key == 'merchantId') {
                        this.opt.search.splice(index, 1);
                    }
                })
                this.opt.columns.forEach((item, index) => {
                    if (item.key == 'merchantName') {
                        this.opt.columns.splice(index, 1);
                    }
                })
            } else {
                this.opt.search.forEach((item, index) => {
                    if (item.key == 'merchantId') {
                        cabinetUtil.getMerchantList().then(res => {
                            item.opt.list = res;
                        })
                    }
                })
            }
            this.pSrc = pdf.createLoadingTask(this.pSrc); //有时pdf文件地址会出现跨域的情况，这里最好处理一下

        },
        deactivated() {
            this.invoiceDialogVisible = false;
            this.invoicePreview = false;
        },

        methods: {
            onGet(opt) {
                let dto = {
                    pageNum: opt.skip,
                    pageSize: opt.limit,
                    startDate: opt.searchForm["date"] ? opt.searchForm["date"][0] : '',
                    endDate: opt.searchForm["date"] ? opt.searchForm["date"][1] : '',
                    ...opt.searchForm
                };
                this.post("/mall-service/v1/order-fp/get-page", dto, {
                    isUseResponse: true
                }).then(res => {
                    res.data.data = res.data.list;
                    if (res.data.data && res.data.data.length > 0) {
                        res.data.data.forEach(item => {
                            item.fpStateStr = ['开票中', '开票成功', '开票失败'][item.fpState];
                            item.fpTypeStr = ['电子普通发票', '增值税专用发票'][item.fpType - 1];
                            item.merchantName = item.merchantName ? item.merchantName : '--';
                        })
                    }
                    opt.cb(res.data);
                });
            },
            clickSendEmail() {
                this.emailDialogVisible = true;
            },
            confirmSendEmail() {
                if (!this.form.sendEmail) {
                    return this.$message({
                        message: "请填写邮箱地址",
                        type: "warning"
                    });
                }
                this.get("/mall-service/v1/order-fp/send-email?id=" + this.form.id + "&email=" + this.form
                    .sendEmail, {}, {
                        isUseResponse: true
                    }).then(res => {
                    if (res.data.code == 0) {
                        this.$message({
                            message: "发送成功！",
                            type: "success"
                        });
                        this.emailDialogVisible = false;
                    }
                })
            },
            checkInvoice() {
                this.invoicePreview = true;
            },
            // 红冲
            updateFun() {
                this.post('/mall-service/v1/order-fp/red?fpId=' + this.form.id, {}, {
                    isUseResponse: true
                }).then(res => {
                    if (res.data.code == 0) {
                        this.$message({
                            message: "红冲成功！",
                            type: "success"
                        });
                        this.$refs.myList.onRefresh();
                    }
                })
            },
            download() {
                axios({
                        method: "get",
                        url: Vue.config.BASE_URL + '/mall-service/v1/order-fp/preview-file?fileName=' + this.pSrc,
                        responseType: "blob"
                    })
                    .then(response => {
                        var elink = document.createElement("a");
                        elink.download = "发票.pdf";
                        elink.style.display = "none";
                        var blob = new Blob([response.data]);
                        elink.href = URL.createObjectURL(blob);
                        document.body.appendChild(elink);
                        elink.click();
                        document.body.removeChild(elink);
                    })
                    .catch(error => {
                        window.open(data["downloadUrl"]);
                    });
            },
            loadPdfHandler(e) {
                this.currentPage = 1; //加载时先加载第一页
            },
            verifyMailboxFormat(val) {
                let myReg =
                    /^([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/;
                if (!myReg.test(val) && val) {
                    this.errorTips = true;
                } else {
                    this.errorTips = false;
                }
            },
        }
    };
</script>
<style lang="scss" scoped>
    .billingRecord-detail-title {
        color: #909399;
        padding-right: 10px;
    }

    .clickable {
        color: #30BAC1;
        cursor: pointer;
    }

    .sendEmailStyle {
        padding-left: 10px;
    }

    .billingRecord-detail {
        margin-left: 30px;
        margin-right: 20px;
    }

    .billingRecord-detail-row {
        padding-bottom: 10px;
    }


    .dialog-footer {
        text-align: center;
    }

    .errorTips {
        color: #f33838;
        font-size: 7px;
        padding-top: 6px;
        padding-left: 2px;
    }
</style>