var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "billingRecord-list" },
    [
      _c("app-list", {
        ref: "myList",
        attrs: { opt: _vm.opt },
        on: { get: _vm.onGet }
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "查看详情",
            width: "500px",
            height: "585px",
            visible: _vm.invoiceDialogVisible,
            "close-on-click-modal": false
          },
          on: {
            "update:visible": function($event) {
              _vm.invoiceDialogVisible = $event
            }
          }
        },
        [
          _c("div", { staticClass: "billingRecord-detail" }, [
            _c("div", { staticClass: "billingRecord-detail-row" }, [
              _c("span", { staticClass: "billingRecord-detail-title" }, [
                _vm._v("发票类型")
              ]),
              _c("span", [_vm._v(_vm._s(_vm.form.fpTypeStr))])
            ]),
            _c("div", { staticClass: "billingRecord-detail-row" }, [
              _c("span", { staticClass: "billingRecord-detail-title" }, [
                _vm._v("发票内容")
              ]),
              _c("span", [
                _vm._v("商品明细，订单号：" + _vm._s(_vm.form.orderId))
              ])
            ]),
            _c("div", { staticClass: "billingRecord-detail-row" }, [
              _c("span", { staticClass: "billingRecord-detail-title" }, [
                _vm._v("抬头类型")
              ]),
              _c("span", [_vm._v(_vm._s(_vm.form.fpTitleStr))])
            ]),
            _c("div", { staticClass: "billingRecord-detail-row" }, [
              _c("span", { staticClass: "billingRecord-detail-title" }, [
                _vm._v("抬头名称")
              ]),
              _c("span", [_vm._v(_vm._s(_vm.form.fpTitleName))])
            ]),
            _vm.form.fpTitle == 2
              ? _c("div", [
                  _c("div", { staticClass: "billingRecord-detail-row" }, [
                    _c("span", { staticClass: "billingRecord-detail-title" }, [
                      _vm._v("单位税号")
                    ]),
                    _c("span", [_vm._v(_vm._s(_vm.form.coTaxNumber))])
                  ]),
                  _c("div", { staticClass: "billingRecord-detail-row" }, [
                    _c("span", { staticClass: "billingRecord-detail-title" }, [
                      _vm._v("注册地址")
                    ]),
                    _c("span", [_vm._v(_vm._s(_vm.form.coAddress))])
                  ]),
                  _c("div", { staticClass: "billingRecord-detail-row" }, [
                    _c("span", { staticClass: "billingRecord-detail-title" }, [
                      _vm._v("注册电话")
                    ]),
                    _c("span", [_vm._v(_vm._s(_vm.form.coPhone))])
                  ]),
                  _c("div", { staticClass: "billingRecord-detail-row" }, [
                    _c("span", { staticClass: "billingRecord-detail-title" }, [
                      _vm._v("开户银行")
                    ]),
                    _c("span", [_vm._v(_vm._s(_vm.form.coBank))])
                  ]),
                  _c("div", { staticClass: "billingRecord-detail-row" }, [
                    _c("span", { staticClass: "billingRecord-detail-title" }, [
                      _vm._v("银行账号")
                    ]),
                    _c("span", [_vm._v(_vm._s(_vm.form.coAccountBank))])
                  ])
                ])
              : _vm._e(),
            _c("div", { staticClass: "billingRecord-detail-row" }, [
              _c("span", { staticClass: "billingRecord-detail-title" }, [
                _vm._v("邮箱地址")
              ]),
              _c("span", [_vm._v(_vm._s(_vm.form.sendEmail))]),
              _c(
                "span",
                {
                  staticClass: "clickable sendEmailStyle",
                  on: { click: _vm.clickSendEmail }
                },
                [_vm._v("发送到邮箱")]
              )
            ]),
            _c("div", { staticClass: "billingRecord-detail-row" }, [
              _c("span", { staticClass: "billingRecord-detail-title" }, [
                _vm._v("开票金额")
              ]),
              _c("span", [
                _vm._v(_vm._s(_vm.form.invoiceTotalPriceTax) + " 元")
              ])
            ]),
            _c("div", { staticClass: "billingRecord-detail-row" }, [
              _c("span", { staticClass: "billingRecord-detail-title" }, [
                _vm._v("开票时间")
              ]),
              _c("span", [_vm._v(_vm._s(_vm.form.invoiceDate))])
            ]),
            _c("div", { staticClass: "billingRecord-detail-row" }, [
              _c("span", { staticClass: "billingRecord-detail-title" }, [
                _vm._v("申请时间")
              ]),
              _c("span", [_vm._v(_vm._s(_vm.form.fpApplyDate))])
            ]),
            _c("div", { staticClass: "billingRecord-detail-row" }, [
              _c("span", { staticClass: "billingRecord-detail-title" }, [
                _vm._v("查看发票")
              ]),
              _c(
                "span",
                { staticClass: "clickable", on: { click: _vm.checkInvoice } },
                [_vm._v("发票预览")]
              )
            ]),
            _c("div", { staticClass: "billingRecord-detail-row" }, [
              _c("span", { staticClass: "billingRecord-detail-title" }, [
                _vm._v("开票状态")
              ]),
              _c("span", [_vm._v(_vm._s(_vm.form.fpStateStr))])
            ]),
            _vm.form.fpState == 2
              ? _c("div", { staticClass: "billingRecord-detail-row" }, [
                  _c("span", { staticClass: "billingRecord-detail-title" }, [
                    _vm._v("失败原因")
                  ]),
                  _c("span", [_vm._v(_vm._s(_vm.form.fpError))])
                ])
              : _vm._e(),
            _c(
              "div",
              { staticClass: "dialog-footer" },
              [
                _c(
                  "el-button",
                  {
                    on: {
                      click: function($event) {
                        _vm.invoiceDialogVisible = false
                      }
                    }
                  },
                  [_vm._v("关闭")]
                )
              ],
              1
            )
          ])
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "发送到邮箱",
            top: "35vh",
            center: "",
            width: "320px",
            height: "260px",
            visible: _vm.emailDialogVisible,
            "close-on-click-modal": false
          },
          on: {
            "update:visible": function($event) {
              _vm.emailDialogVisible = $event
            }
          }
        },
        [
          _c(
            "div",
            { staticStyle: { "padding-bottom": "15px" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入邮箱" },
                on: {
                  input: function($event) {
                    _vm.verifyMailboxFormat(_vm.form.sendEmail)
                  }
                },
                model: {
                  value: _vm.form.sendEmail,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.form,
                      "sendEmail",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "form.sendEmail"
                }
              }),
              _vm.errorTips
                ? _c("div", { staticClass: "errorTips" }, [
                    _vm._v("邮箱格式不正确")
                  ])
                : _vm._e()
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "dialog-footer" },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.emailDialogVisible = false
                    }
                  }
                },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.confirmSendEmail }
                },
                [_vm._v("发送")]
              )
            ],
            1
          )
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "发票预览",
            top: "10vh",
            visible: _vm.invoicePreview,
            "close-on-click-modal": false
          },
          on: {
            "update:visible": function($event) {
              _vm.invoicePreview = $event
            }
          }
        },
        [
          _c("iframe", {
            attrs: {
              src: _vm.pSrc,
              height: "480vh",
              width: "100%",
              frameborder: "0"
            }
          }),
          _c(
            "div",
            { staticClass: "dialog-footer" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      _vm.download()
                    }
                  }
                },
                [_vm._v("下载")]
              ),
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.invoicePreview = false
                    }
                  }
                },
                [_vm._v("关闭")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }